import React, { Component } from 'react';
import BackgroundSlideshow from 'react-background-slideshow';

import Landscape1 from './assets/landscape-1.jpg';
import Landscape2 from './assets/landscape-2.jpg';
import Landscape3 from './assets/landscape-3.jpg';
import Landscape4 from './assets/landscape-4.jpg';
import Landscape5 from './assets/landscape-5.jpg';
import Landscape6 from './assets/landscape-6.jpg';

import './App.css';

const LANG = 'en';

const slideshow = [
 Landscape1,
 Landscape2,
 Landscape3,
 Landscape4,
 Landscape5,
 Landscape6,
];

const translations = {
  fr: {
    title: 'Terrain360.ca - Imagerie aérienne professionnelle',
    header: {
      title: 'Contenu visuel pour vos présentations professionnelles',
      subtitle: 'Spécialiste en traçage, limites de terrain par satellite',
    },
    services: {
      satellite: 'IMAGERIE SATELLITAIRE',
      drone: 'DRONE 360 VIRTUEL',
      imagery: 'IMAGERIE PAR DRONE',
      uav: 'DRONE UAV',
    },
    construction: 'Site web en construction',
    freeQuote: 'Soumission gratuite!',
    help: 'Laissez-nous vous aider avec votre projet!',
    email: 'info@terrain360.ca',
  },
  en: {
    title: 'Land360.ca - Professionnal aerial imagery',
    header: {
      title: 'We create visual content for your listings',
      subtitle: 'We specialize in digital land & mountain parcel boundaries',
    },
    services: {
      satellite: 'SATELLITE IMAGERY',
      drone: '360 DRONE TOURS',
      imagery: 'DRONE IMAGERY',
      uav: 'UAV DRONE',
    },
    construction: 'Website coming soon',
    freeQuote: 'Free quote!',
    help: 'Let us help you with your project!',
    email: 'info@land360.ca',
  }
};

const Divider = () => (
  <span className="divider">/</span>
);

const Services = ({ i18n }) => (
  <section className="services">
    <p>{i18n.services.satellite} <Divider /> {i18n.services.drone}</p>
    <p>{i18n.services.imagery} <Divider /> {i18n.services.uav}</p>
  </section>
);

const Banner = ({ i18n }) => (
  <div className="banner">
    <h1>{i18n.header.title}</h1>
    <h2>{i18n.header.subtitle}</h2>
  </div>
);

const Main = ({ lang, i18n }) => {
  const to = lang === 'en' ? 'http://terrain360.ca' : 'http://land360.ca';
  const link = lang === 'en' ? 'FR' : 'EN';

  return (
    <div className="main">
      <div className="header">
        <img src={`assets/images/logo-${lang}.png`} className="logo" alt="Logo" />
        <div className="select-i18n">
          <a href={to}>{link}</a>
        </div>
      </div>
      <Banner i18n={i18n} />
      <Services i18n={i18n} />
      <section className="help">
        <p>{i18n.help}</p>
        <p>{i18n.freeQuote}</p>
        <p>{i18n.construction}</p>
      </section>
      <footer>
        <p>
          <a href={`mailto:${i18n.email}`}>{i18n.email}</a> <Divider /> 514.717.1475
        </p>
      </footer>
    </div>
  );
};
class App extends Component {
  componentDidMount() {
    document.title = translations[LANG].title;
  }

  render() {
    return (
      <div className="app">
        <div className="slideshow-overlay"></div>
        <BackgroundSlideshow images={slideshow} />
        <div className="overlay">
         <Main lang={LANG} i18n={translations[LANG]} />
        </div>
      </div>
    );
  }
}

export default App;
